
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface NewProductData {
  ID: string;
  Number: string;
  Form: string;
  ElementContent: string;
}
interface NewProductionData {
  Line: string;
  MachineNumber: string;
  Loading: string;
  QuenchingType: string;
}

export default defineComponent({
  name: "overview",
  components: {},
  data(): {
    DataType: string;
  } {
    return {
      DataType: "",
    };
  },
  setup() {
    // 導入資料
    const Product = ref<NewProductData>({
      ID: "",
      Number: "",
      Form: "",
      ElementContent: "",
    });
    const Production = ref<NewProductionData>({
      Line: "",
      MachineNumber: "",
      Loading: "",
      QuenchingType: "",
    });
    const Update = () => {
      alert("更新完成");
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("匯入更新", ["戰情中台"]);
    });
    return {
      Product,
      Production,
      Update,
    };
  },
});
